<template>
  <div>

    <div class="container py-6" >
      <div style="max-width: 800px">
      <h1 class="is-title is-size-3 mb-5">Add your site to {{siteName}}'s Index</h1>
      
      <div v-if="success" class="mb-5">
        <b-notification class="mb-5" type="is-success">
          Thanks! Your site has been submitted for review. 
        </b-notification>
        
        <div class="buttons is-justify-content-center">
          <b-button @click="addAnotherSite">Add another site</b-button>
        </div>
      </div>

      <b-notification class="mb-5" type="is-danger" v-if="error">
        {{error}}
      </b-notification>

      <hr />

      <div v-if="!success">
        <p class="mb-5">
          While the {{siteName}} spider is hard at work building our search index, it hasn't covered every corner of the web just yet. 
          If you'd like to help out by recommending your site for inclusion, just fill out the form below.
        </p>

        <hr />

        <form v-on:submit.prevent="checkCaptcha" style="max-width: 650px">

          <div class="field mb-5">
            <label class="label has-text-primary">Website URL</label>
            <div class="control">
              <input autocomplete="off" placeholder="https://your-website.com" class="input" type="text" maxlength="255" name="website" v-model="website" required />
            </div>
          </div>

          <div class="field mb-5">
            <label class="label has-text-primary">What keywords should it rank for? (optional)</label>
            <div class="control">
                <!--<input class="input" type="text" maxlength="255" v-bind="keywords" name="keywords" />-->
                <b-taginput
                    v-model="keywords"
                    allow-new
                    icon="label"
                    placeholder="Add keywords"
                    maxtags="10"
                    >
                </b-taginput>
            </div>
          </div>

          <vue-hcaptcha
            ref="captcha" 
            :sitekey="sitekey"
            @verify="onVerify"
            @expired="onExpired"
            @error="onError"
            size="invisible"
            class="hcap mb-5"
            :class="{'has-error': error === ERROR_CODES.INVALID_CAPTCHA}"
          ></vue-hcaptcha>

          <b-button type="is-primary" native-type="submit">
            Submit
          </b-button>

        </form>
      </div>
      </div>
    </div>  
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import axios from 'axios';

const ERROR_CODES = {
  INVALID_CAPTCHA: 'Please validate that you are not a robot',
  INVALID_URL_FORMAT: 'The URL you specified is invalid',
  INVALID_URL_LENGTH: 'The URL you specified is too long',
  INVALID_KEYWORDS_LENGTH: 'The keywords you specified are too long',
  UNDEFINED: 'There was an error processing your request, please check the form for errors and try again.'
}

const hcaptchaSiteKey = process.env.VUE_APP_HCAPTCHA_KEY;

export default {
  name: 'SubmitUrl',
  data: () => ({
    siteName: process.env.VUE_APP_SITE_NAME,
    sitekey: hcaptchaSiteKey,
    keywords: [],
    website: null,
    verified: false,
    error: null,
    expired: false,
    success: false,
    responseCode: null,
    ERROR_CODES: ERROR_CODES
  }),
  components: {
    VueHcaptcha
  },
  methods:{
    addAnotherSite(){
      this.keywords = [];
      this.website = null;
      this.success = false;
    },
    onVerify(response){
      this.verified = true;
      this.error = null;
      this.responseCode = response;
      this.submitForm();
    },
    onExpired(){
      this.verified = false;
      this.responseCode = null;
    },
    onError(error){
      this.verified = false;
      console.error(error);
    },
    checkCaptcha(){
      this.$refs.captcha.execute();
    },
    async submitForm(){
      const url = process.env.VUE_APP_SUBMIT_URL;
      //const checkCaptcha = process.env.VUE_APP_CHECK_CAPTCHA === 'true';
      this.success = false;
      this.error = null;

      //if(checkCaptcha && !this.verified){
      //  this.error = this.ERROR_CODES.INVALID_CAPTCHA;
      //  return;
      //}
      let website = this.website;

      if(website.indexOf('http') !== 0){
        website = 'https://' + website;
      }

      let result;
      let data = {
        'hCaptchaResponse': this.responseCode,
        'url': website,
        'keywords': this.keywords
      }

      try{
        result = await axios.post(url, data);
        
        if(result.data && result.data.error){
          let message = this.ERROR_CODES[result.data.error] || this.ERROR_CODES.UNDEFINED;
          throw(message);
        }else{
          this.success = true;
        }
      }catch(err){
        this.error = err;
      }
      
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/_vars.scss';

.top-bar{
  background-color: #fafaff;
}
.hcap.has-error iframe{
  border: 3px solid $primary;
}
h1{
  line-height: 1.2;
}
@media screen and (max-width: 500px){
  .hcap{
    transform: scale(0.9);
    transform-origin: left;
  }
}
</style>
